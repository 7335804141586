import React from 'react'
import Layout from "../components/layout"
import Vindicated from '../images/hero-vindicated.png'
import SEO from '../components/seo'

export default function Article() {
    return (
        <Layout>
            <SEO title="Hero Vindicated" image={Vindicated} />
            <h1>
            Hero Vindicated
            </h1>
            <img src={Vindicated} />
            <p>A new perspective reveals that it was not Hero, but her friend Margaret, who was seen through Hero’s window several nights ago.</p> 

            <p>The newly uncovered picture clearly captures the faces of Margaret and her boyfriend, Borachio. Apparently, the two met at the Mayor’s banquet and quickly hit it off. While it is unclear how their romantic tryst wound up occurring in Hero’s room, one can attest to sneaking private moments where one can.</p>

            <p>The Scandalous Crier would like to express our sincere apologies for publishing the picture that falsely identified Hero. Our thoughts and prayers are with her family.</p>      
        </Layout>
    )
}
